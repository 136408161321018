import React, { useEffect, useState } from 'react';
import { database } from '../firebaseConfig';
import { ref, get } from 'firebase/database';
import { Spinner, Button, Nav } from 'react-bootstrap';
import './Post.css';

const Post = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState('time'); // Default sorting option: time
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [cityOpen, setCityOpen] = useState(false);


  useEffect(() => {
    const fetchPostsWithProfiles = async () => {
      try {
        const postsRef = ref(database, 'posts');
        const postsSnapshot = await get(postsRef);

        if (postsSnapshot.exists()) {
          const postsData = [];
          const postPromises = [];

          postsSnapshot.forEach((postSnap) => {
            const post = postSnap.val();
            post.postId = postSnap.key;
            postsData.push(post);

            // Fetch poster profile details
            const profileRef = ref(database, `profiles/${post.posterid}`);
            const profilePromise = get(profileRef).then((profileSnap) => {
              if (profileSnap.exists()) {
                const profile = profileSnap.val();
                post.posterName = profile.firstname + ' ' + profile.lastname;
                post.posterProfilePic = profile.profilePicture;
                post.collegeYear = profile.collegeYear
              } else {
                post.posterName = 'Unknown User';
                post.posterProfilePic = 'https://via.placeholder.com/150';
              }
            });

            postPromises.push(profilePromise);
          });

          // Wait for all profile fetches to complete
          await Promise.all(postPromises);
          setPosts(postsData);
        } else {
          console.log('No posts found');
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPostsWithProfiles();
  }, []);

  // Sort posts based on selected option
  const sortedPosts = [...posts].sort((a, b) => {
    switch (sortOption) {
      case 'likes':
        return (b.likes?.length || 0) - (a.likes?.length || 0);
      case 'views':
        return (b.views || 0) - (a.views || 0);
      case 'comments':
        return (b.comments?.length || 0) - (a.comments?.length || 0);
      case 'trending':
        return ((b.likes?.length || 0) + (b.comments?.length || 0)) - ((a.likes?.length || 0) + (a.comments?.length || 0));
      case 'time':
      default:
        return parseInt(b.date) - parseInt(a.date);
    }
  });

  if (loading) {
    return (
      <div className="loading-indicator">
        <Spinner animation="border" variant="primary" />
        <p>Loading posts...</p>
      </div>
    );
  }

  return (
    <div className="post-detail-page">
      <h3>Posts</h3>

      {/* Sort Tabs */}
      <Nav variant="filter-tabs" activeKey={sortOption} onSelect={(selectedKey) => setSortOption(selectedKey)}>
      <Nav.Item>
        <Nav.Link eventKey="time">Newest</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="likes">Most Liked</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="views">Most Viewed</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="comments">Most Commented</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="trending">Trending</Nav.Link>
      </Nav.Item>
    </Nav>

      {sortedPosts.length > 0 ? (
        sortedPosts.map((post) => (
          <div key={post.postId} className="post-item">
            <div className="post-header">
              <img src={post.posterProfilePic || 'https://via.placeholder.com/150'} alt="Poster" className="post-profile-pic" />
              <div>
                <h4>{post.posterName || 'Unknown User'}</h4>
                <p>{post.college} - {post.collegeYear}</p>
                <p>{new Date(parseInt(post.date)).toLocaleDateString()}</p>
              </div>
            </div>

            <p><strong>Caption:</strong> {post.caption}</p>
            {post.postmedia && post.postmedia.length > 0 && (
              <div className="media-collage">
                {post.postmedia.map((media, index) => (
                  <img key={index} src={media} alt={`Media ${index}`} className="collage-item" />
                ))}
              </div>
            )}

            <div className="post-details">
              <p><strong>Privacy:</strong> {post.postPrivacy || 'Public'}</p>
              <p><strong>Location:</strong> {post.location || 'Not specified'}</p>

              {/* Collapsible Category Section */}
              <div className="collapsible-section">
                <button className="collapsible-button" onClick={() => setCategoryOpen((prev) => !prev)}>
                  {categoryOpen ? 'Hide Categories' : 'Show Categories'}
                </button>
                {categoryOpen && (
                  <ul className="category-list">
                    {post.postCategory?.length > 0 ? (
                      post.postCategory.map((category, index) => (
                        <li key={index}>{category}</li>
                      ))
                    ) : (
                      <li>General</li>
                    )}
                  </ul>
                )}
              </div>

              <div className="collapsible-section">
                <button className="collapsible-button" onClick={() => setCityOpen((prev) => !prev)}>
                  {cityOpen ? 'Hide Content' : 'Show Content'}
                </button>
                {cityOpen && (
                  <ul className="city-list">
                    {post.postContent?.length > 0 ? (
                      post.postContent.map((city, index) => (
                        <li key={index}>{city}</li>
                      ))
                    ) : (
                      <li>Unknown</li>
                    )}
                  </ul>
                )}
              </div>

              {/* Collapsible City Section */}
              <div className="collapsible-section">
                <button className="collapsible-button" onClick={() => setCityOpen((prev) => !prev)}>
                  {cityOpen ? 'Hide Cities' : 'Show Cities'}
                </button>
                {cityOpen && (
                  <ul className="city-list">
                    {post.postCity?.length > 0 ? (
                      post.postCity.map((city, index) => (
                        <li key={index}>{city}</li>
                      ))
                    ) : (
                      <li>Unknown</li>
                    )}
                  </ul>
                )}
              </div>

              <p><strong>Likes:</strong> {post.likes ? post.likes.length : 0}</p>
              <p><strong>Comments:</strong> {post.comments ? post.comments.length : 0}</p>
              <p><strong>Views:</strong> {post.views || 0}</p>
            </div>


            <div className="admin-actions">
              <Button variant="danger" onClick={() => console.log(`Delete post ${post.postId}`)}>Delete Post</Button>
              <Button variant="warning" onClick={() => console.log(`Flag post ${post.postId}`)}>Flag Post</Button>
            </div>
          </div>
        ))
      ) : (
        <p>No posts available.</p>
      )}
    </div>
  );
};

export default Post;
