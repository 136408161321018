import React from 'react';
import './Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faFire, faBell, faArrowUp, faArrowDown, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

function Dashboard() {
  // Example data for the chart (daily active users)
  const chartData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Daily Active Users',
        data: [150, 230, 200, 270, 290, 320, 340],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: '#1d72b8',
        borderWidth: 2,
        fill: true,
        tension: 0.4, // Add curve to the line chart
      },
    ],
  };

  // Example data for the latest reports or appeals
  const reports = [
    { id: 1, title: 'User Appeal for Account Restoration', date: '2024-10-21', status: 'Pending' },
    { id: 2, title: 'Monthly User Engagement Report', date: '2024-10-15', status: 'Reviewed' },
    { id: 3, title: 'System Outage Notification', date: '2024-10-10', status: 'Resolved' },
  ];

  return (
    <div className="dashboard">
      {/* Top Header with Date and Push Notification */}
      <div className="dashboard-header">
        <h2>Admin Dashboard</h2>
        <div className="dashboard-controls">
          <div className="date">{new Date().toLocaleDateString()}</div>
          <input type="text" placeholder="Search..." className="search-bar" />
          <button className="push-notification-btn">
            <FontAwesomeIcon icon={faBell} /> Send Push Notification
          </button>
        </div>
      </div>

      {/* Main dashboard panels */}
      <div className="dashboard-panels">
        <div className="panel performance-panel">
          <div className="panel-header">
            <FontAwesomeIcon icon={faUsers} className="panel-icon" />
            <h3>Overall Users</h3>
          </div>
          <div className="panel-body">
            <h1>12,345</h1>
            <p>Total Users</p>
            <div className="performance-stats">
              <span className="stat">
                <FontAwesomeIcon icon={faArrowUp} className="up-arrow" /> 5% increase this week
              </span>
              <span className="stat">
                <FontAwesomeIcon icon={faArrowDown} className="down-arrow" /> 3% decrease in activity
              </span>
            </div>
          </div>
        </div>

        <div className="panel trending-panel">
          <div className="panel-header">
            <FontAwesomeIcon icon={faFire} className="panel-icon" />
            <h3>Trending Topics</h3>
          </div>
          <div className="panel-body">
            <ul className="trending-list">
              <li>#TechInnovations</li>
              <li>#ClimateChange</li>
              <li>#AIRevolution</li>
              <li>#StartUpWorld</li>
              <li>#Crypto2024</li>
            </ul>
          </div>
        </div>

        <div className="panel chart-panel">
          <div className="panel-header">
            <h3>Daily Active Users</h3>
          </div>
          <div className="panel-body">
            <Line data={chartData} />
          </div>
        </div>

        {/* New Panel for Latest Reports or Appeals */}
        <div className="panel reports-panel">
          <div className="panel-header">
            <FontAwesomeIcon icon={faFileAlt} className="panel-icon" />
            <h3>Latest Reports & Appeals</h3>
          </div>
          <div className="panel-body">
            <ul className="reports-list">
              {reports.map(report => (
                <li key={report.id}>
                  <span className="report-title">{report.title}</span>
                  <span className="report-date">{report.date}</span>
                  <span className="report-status">{report.status}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
