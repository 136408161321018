import React, { useEffect, useState } from 'react';
import { database } from '../firebaseConfig';
import { ref, onValue,set,remove } from 'firebase/database';
import './PendingAds.css';

const PendingAds = () => {
    const [pendingAds, setPendingAds] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPendingAds = () => {
            // Reference to the needApproval node
            const campaignRef = ref(database, 'businessAds/needApproval/');

            onValue(campaignRef, (snapshot) => {
                const campaigns = [];
                snapshot.forEach((userSnapshot) => {
                    userSnapshot.forEach((childSnapshot) => {
                        const campaignData = childSnapshot.val();
                        campaigns.push({ id: childSnapshot.key, ...campaignData });
                    });
                });
                setPendingAds(campaigns);
                setLoading(false);
            }, (error) => {
                console.error('Error fetching pending ads:', error);
                setLoading(false);
            });
        };

        fetchPendingAds();
    }, []);

    const handleApprove = async (id) => {
        const approvedCampaignRef = ref(database, `businessAds/approved/${id}`);
    
        // Move the campaign to approved section
        const campaignData = pendingAds.find(ad => ad.id === id);
        if (campaignData) {
            await set(approvedCampaignRef, campaignData);
        }
    
        // Remove from pending
        const campaignToRemoveRef = ref(database, `businessAds/needApproval/${campaignData.userId}/${id}`);
        await remove(campaignToRemoveRef);
        
        alert('Campaign approved successfully!');
        // Optionally, you can re-fetch the pending ads here
    };
    

    const handleReject = async (id) => {
        // Logic for rejecting the ad
        // This could involve deleting the ad from the pending section
        const campaignToRemoveRef = ref(database, `businessAds/needApproval/${pendingAds.find(ad => ad.id === id).userId}/${id}`);
        await remove(campaignToRemoveRef);
        alert('Campaign rejected successfully!');
    };

    return (
        <div className="pending-ads-container">
            <h1>Pending Ads for Approval</h1>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <ul>
                    {pendingAds.map((ad) => (
                        <li key={ad.id} className="ad-item">
                            <h2>{ad.title}</h2>
                            <p><strong>Description:</strong> {ad.description}</p>
                            <p><strong>Audience:</strong> {ad.audience}</p>
                            <p><strong>Location:</strong> {ad.location}</p>
                            <p><strong>Budget:</strong> ₹{ad.budget}</p>
                            <p><strong>Campaign Period:</strong> {ad.campaignPeriod} days</p>
                            <p><strong>Start Date:</strong> {new Date(ad.startDate).toLocaleDateString()}</p>
                            <p><strong>User ID:</strong> {ad.userId}</p>
                            <p><strong>Name:</strong> {ad.name}</p>
                            <div className="media">
                                {ad.mediaURL && (
                                    <>
                                        <h4>Media:</h4>
                                        {ad.mediaURL.endsWith('.mp4') ? (
                                            <video controls width="250">
                                                <source src={ad.mediaURL} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        ) : (
                                            <img src={ad.mediaURL} alt={ad.title} width="250" />
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="buttons">
                                <button onClick={() => handleApprove(ad.id)}>Approve</button>
                                <button onClick={() => handleReject(ad.id)}>Reject</button>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
    
};

export default PendingAds;
