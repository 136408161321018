// src/components/MainContainer.js
import React from 'react';
import './MainContainer.css';

const MainContainer = ({ children }) => {
  return (
    <div className="main-container">
      {children}
    </div>
  );
};

export default MainContainer;
