import React, { useEffect, useState } from 'react'; 
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUsers, faBullhorn, faNewspaper, faChartLine, faBell, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { signOut, onAuthStateChanged } from 'firebase/auth'; // Import the signOut and onAuthStateChanged methods
import { auth } from '../firebaseConfig'; // Import your Firebase configuration
import './Sidebar.css';

function Sidebar() {
  const navigate = useNavigate(); // Hook to navigate programmatically
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track user's sign-in status
  const [error, setError] = useState(''); // To display error message

  // Monitor user's authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true); // User is signed in
      } else {
        setIsAuthenticated(false); // User is not signed in
        navigate('/login'); // Redirect to login page after sign-out

      }
    });

    // Cleanup the subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/login'); // Redirect to login page after sign-out
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  const handleProtectedNavigation = (e) => {
    if (!isAuthenticated) {
      e.preventDefault();
      setError('Please sign in to navigate'); // Show error message
    }
    else{
      setError(''); // Show error message
    }
  };

  return (
    <div className="sidebar">
      {/* Logo and Company Name */}
      <div className="sidebar-header">
        <img src="/logop.png" alt="Company Logo" className="sidebar-logo" />
        <h3>Admin Panel</h3>
      </div>

      {/* Display Error Message */}
      {error && <p className="error-message">{error}</p>}

      {/* Navigation Links */}
      <ul className="sidebar-links">
        <li>
          <Link to="/dashboard" onClick={handleProtectedNavigation}>
            <FontAwesomeIcon icon={faTachometerAlt} className="sidebar-icon" /> Dashboard
          </Link>
        </li>
        <li>
          <Link to="/users" onClick={handleProtectedNavigation}>
            <FontAwesomeIcon icon={faUsers} className="sidebar-icon" /> Users
          </Link>
        </li>
        <li>
          <Link to="/advertisers" onClick={handleProtectedNavigation}>
            <FontAwesomeIcon icon={faBullhorn} className="sidebar-icon" /> Advertisers
          </Link>
        </li>
        <li>
          <Link to="/posts" onClick={handleProtectedNavigation}>
            <FontAwesomeIcon icon={faNewspaper} className="sidebar-icon" /> Posts
          </Link>
        </li>
        <li>
          <Link to="/approveAds" onClick={handleProtectedNavigation}>
            <FontAwesomeIcon icon={faBullhorn} className="sidebar-icon" /> Approve Ads
          </Link>
        </li>
        <li>
          <Link to="/reports" onClick={handleProtectedNavigation}>
            <FontAwesomeIcon icon={faChartLine} className="sidebar-icon" /> Reports
          </Link>
        </li>
        <li>
          <Link to="/analytics" onClick={handleProtectedNavigation}>
            <FontAwesomeIcon icon={faChartLine} className="sidebar-icon" /> Analytics
          </Link>
        </li>
        <li>
          <Link to="/notifications" onClick={handleProtectedNavigation}>
            <FontAwesomeIcon icon={faBell} className="sidebar-icon" /> Notifications
          </Link>
        </li>
        <li>
          <Link to="/settings" onClick={handleProtectedNavigation}>
            <FontAwesomeIcon icon={faCog} className="sidebar-icon" /> Settings
          </Link>
        </li>
      </ul>

      {/* Conditionally Render Logout Button */}
      {isAuthenticated && (
        <div className="sidebar-footer">
          <button className="logout-button" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} className="sidebar-icon" /> Logout
          </button>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
