import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAikLBmzW2i9lDEJKM8zb7aMVwB94SQTEE",
    authDomain: "reclectionindia.firebaseapp.com",
    databaseURL: "https://reclectionindia-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "reclectionindia",
    storageBucket: "reclectionindia.appspot.com",
    messagingSenderId: "479797569905",
    appId: "1:479797569905:web:dc440ad78fd3f5d7f3aed0",
    measurementId: "G-ZPWJXP6KNX"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const db = getStorage(app);
const storage = getStorage(app);

export { app, auth, database, storage,db };
