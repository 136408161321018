import React, { useEffect, useState } from 'react';
import { database } from '../firebaseConfig';
import { ref, onValue } from 'firebase/database';
import './Reports.css'; // Assuming you have a CSS file for styling
import { Spinner, Dropdown, Badge } from 'react-bootstrap'; // Import Bootstrap components

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const reportsRef = ref(database, '/reports');

    onValue(reportsRef, (snapshot) => {
      const reportsData = snapshot.val();
      const reportsArray = [];

      for (const reportId in reportsData) {
        const reportDetails = reportsData[reportId][reportId]; // Access nested report data
        reportsArray.push({ id: reportId, ...reportDetails });
      }

      setReports(reportsArray);
      setLoading(false);
    });
  }, []);

  const handleResolveReport = (reportId) => {
    console.log(`Report ${reportId} marked as resolved`);
    // Here you can add functionality to update the report's status in Firebase
  };

  return (
    <div className="reports-page">
      <h2>Reports</h2>

      {loading ? (
        <div className="loading-indicator">
          <Spinner animation="border" variant="primary" />
          <p>Loading reports...</p>
        </div>
      ) : (
        <div className="reports-list">
          {reports.length === 0 ? (
            <p>No reports found.</p>
          ) : (
            reports.map((report) => (
              <div key={report.id} className="report-card">
                <div className="report-header">
                  <p><strong>Report ID:</strong> {report.id}</p>
                  <p><strong>Reported By:</strong> {report.reportedBy}</p>
                  <p>
                    <strong>Status:</strong>{' '}
                    <Badge bg={report.status === 'Reported' ? 'danger' : 'success'}>
                      {report.status}
                    </Badge>
                  </p>
                </div>
                <div className="report-body">
                  <p><strong>Report Type:</strong> {report.reportType}</p>
                  {report.postId && (
                    <p><strong>Post ID:</strong> {report.postId}</p>
                  )}
                  <p><strong>Reason:</strong> {report.reason}</p>
                </div>
                <div className="report-actions">
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleResolveReport(report.id)}>
                        Mark as Resolved
                      </Dropdown.Item>
                      {/* Add more actions here if needed */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default Reports;
