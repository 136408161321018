// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Sidebar from './components/Sidebar';
import MainContainer from './components/MainContainer';
import Dashboard from './components/Dashboard';
import './App.css'; // Ensure the app.css is linked for the layout
import Users from './pages/Users';
import Advertisers from './pages/Advertisers';
import BusinessProfile from './pages/BusinessProfile';
import UserProfile from './pages/UserProfile';
import PendingAds from './pages/PendingAds'
import Reports from './pages/Reports';
import Post from './pages/Post';





function App() {
  return (
    <Router>
      <div className="app">
        <Sidebar /> {/* Sidebar remains fixed */}
        <MainContainer> {/* Main content container */}
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/advertisers" element={<Advertisers />} />
            <Route path="/business/:uid" element={<BusinessProfile />} />
            <Route path="/user/:userId" element={<UserProfile />} />
            <Route path="/approveAds" element={<PendingAds />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/posts" element={<Post />} />
          </Routes>
        </MainContainer>
      </div>
    </Router>
  );
}

export default App;
