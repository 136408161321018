import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { database, auth } from '../firebaseConfig';
import { ref, onValue, update } from 'firebase/database';
import { Button, Card, Container, Row, Col, Modal } from 'react-bootstrap';
import './BusinessProfile.css';

const BusinessProfile = () => {
  const { uid } = useParams();
  const [business, setBusiness] = useState({});
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [pendingAds, setPendingAds] = useState([]);
  const [approvedAds, setApprovedAds] = useState([]);
  const [rejectedAds, setRejectedAds] = useState([]);

  useEffect(() => {
    // Fetching business data
    const businessRef = ref(database, `/business/${uid}`);
    onValue(businessRef, (snapshot) => {
      const businessData = snapshot.val();
      setBusiness(businessData);
    });

    // Fetching pending ads associated with the business
    const pendingRef = ref(database, `/businessAds/needApproval/${uid}`);
    onValue(pendingRef, (snapshot) => {
      const adsData = snapshot.val();
      if (adsData) {
        const pending = [];
        for (const childId in adsData) {
          for (const adId in adsData[childId]) {
            const ad = { id: adId, ...adsData[childId][adId] };
            pending.push(ad);
          }
        }
        setPendingAds(pending);
      }
    });

    // Fetching approved ads associated with the business
    const approvedRef = ref(database, `/businessAds/approved/`);
    onValue(approvedRef, (snapshot) => {
      const adsData = snapshot.val();
      if (adsData) {
        const approved = [];
        for (const adId in adsData) {
          const ad = { id: adId, ...adsData[adId] };
          // Check if the userId matches the current uid
          if (ad.userId === uid) {
            approved.push(ad);
          }
        }
        setApprovedAds(approved);
      }
    });

    // Fetching rejected ads associated with the business
    const rejectedRef = ref(database, `/businessAds/rejected/${uid}`);
    onValue(rejectedRef, (snapshot) => {
      const adsData = snapshot.val();
      if (adsData) {
        const rejected = [];
        for (const adId in adsData) {
          const ad = { id: adId, ...adsData[adId] };
          rejected.push(ad);
        }
        setRejectedAds(rejected);
      }
    });

  }, [uid]);

  const handleBlockBusiness = () => {
    update(ref(database, `/business/${uid}`), { status: 'blocked' });
    setShowBlockModal(false);
  };

  return (
    <Container className="profile-page">
      {/* Header Section */}
      <div className="profile-header-section">
        <div className="profile-cover-photo">
          <img
            src={business.coverPhotoURL || `https://via.placeholder.com/150`}
            alt={business.businessUsername}
            className="cover-photo"
          />
        </div>

        <Card className="profile-main-card">
          <Row>
            <Col md={3}>
              <div className="profile-photo-container">
                <img
                  src={business.profilePhotoURL || `https://via.placeholder.com/150`}
                  alt={business.businessUsername}
                  className="profile-photo"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="profile-info">
                <h2 className="business-type">{business.firstName} {business.lastName}</h2>
                <p className="business-type">@{business.businessUsername}</p>
                <p className="business-type">"{business.bio}"</p>
                <p className="business-type">{business.businessType}</p>
                <div className="profile-stats">
                  <div className="stat-item">
                    <span className="stat-value">{business.totalCampaigns || 0}</span>
                    <span className="stat-label">Campaigns</span>
                  </div>
                  <div className="stat-item">
                    <span className="stat-value">{business.totalAds || 0}</span>
                    <span className="stat-label">Active Ads</span>
                  </div>
                  <div className="stat-item">
                    <span className="stat-value">₹{business.totalExpenditure || 0}</span>
                    <span className="stat-label">Spent</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="profile-actions">
                <Button variant="primary" className="action-button">
                  <i className="fas fa-envelope"></i> Message
                </Button>
                <Button variant="warning" className="action-button">
                  <i className="fas fa-bell"></i> Notify
                </Button>
                <Button
                  variant="danger"
                  className="action-button"
                  onClick={() => setShowBlockModal(true)}
                >
                  <i className="fas fa-ban"></i> Block
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </div>

      {/* Details Section */}
      <Row className="profile-content">
        <Col md={4}>
          <Card className="info-card">
            <Card.Header>Business Details</Card.Header>
            <Card.Body>
              <p><strong>Owner:</strong> {business.firstName}</p>
              <p><strong>Contact:</strong> {business.phone}</p>
              <p><strong>Email:</strong> {business.email || "Not specified"}</p>
              <p><strong>Address:</strong> {business.address}</p>
              <p><strong>Website:</strong>
                <a href={business.website} target="_blank" rel="noopener noreferrer">
                  {business.website}
                </a>
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          {/* Tiles for Pending, Approved, and Rejected Ads */}
          <Row>
            {/* Pending Ads */}
            <Col md={4}>
              <Card className="info-card">
                <Card.Header>Pending Ads</Card.Header>
                <Card.Body>
                  {pendingAds.length === 0 ? (
                    <p>No pending ads available.</p>
                  ) : (
                    <ul>
                      {pendingAds.map((ad) => (
                        <li key={ad.id}>
                          <h4>{ad.title}</h4>
                          <p>{ad.description}</p>
                          <p><strong>Payment Status:</strong> {ad.paymentStatus || "Not specified"}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </Card.Body>
              </Card>
            </Col>

            {/* Approved Ads */}
            <Col md={4}>
              <Card className="info-card">
                <Card.Header>Approved Ads</Card.Header>
                <Card.Body>
                  {approvedAds.length === 0 ? (
                    <p>No approved ads available.</p>
                  ) : (
                    <ul>
                      {approvedAds.map((ad) => (
                        <li key={ad.id}>
                          <h4>{ad.title}</h4>
                          <p>{ad.description}</p>
                          <p><strong>Payment Status:</strong> {ad.paymentStatus || "Not specified"}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </Card.Body>
              </Card>
            </Col>

            {/* Rejected Ads */}
            <Col md={4}>
              <Card className="info-card">
                <Card.Header>Rejected Ads</Card.Header>
                <Card.Body>
                  {rejectedAds.length === 0 ? (
                    <p>No rejected ads available.</p>
                  ) : (
                    <ul>
                      {rejectedAds.map((ad) => (
                        <li key={ad.id}>
                          <h4>{ad.title}</h4>
                          <p>{ad.description}</p>
                          <p><strong>Payment Status:</strong> {ad.paymentStatus || "Not specified"}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Block Business Modal */}
      <Modal show={showBlockModal} onHide={() => setShowBlockModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Block Business</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to block this business?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBlockModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleBlockBusiness}>
            Block
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default BusinessProfile;
