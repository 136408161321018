import React, { useEffect, useState } from 'react';
import { database } from '../firebaseConfig'; // Import your firebaseConfig
import { ref, onValue } from 'firebase/database';
import './Advertisers.css'; // Import CSS for styling
import { Spinner } from 'react-bootstrap'; // Import Bootstrap Spinner
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Advertisers = () => {
  const [advertisers, setAdvertisers] = useState([]);
  const [displayedAdvertisers, setDisplayedAdvertisers] = useState([]); // Advertisers to display
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [loading, setLoading] = useState(true); // Loading state
  const [lastFetchedIndex, setLastFetchedIndex] = useState(20); // To track the last fetched index
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    // Fetch advertiser data from Firebase Realtime Database
    const advertisersRef = ref(database, '/business');
    onValue(advertisersRef, (snapshot) => {
      const advertisersData = snapshot.val();
      const advertisersList = [];

      for (const uid in advertisersData) {
        if (advertisersData.hasOwnProperty(uid)) {
          advertisersList.push({ uid, ...advertisersData[uid] });
        }
      }

      setAdvertisers(advertisersList);
      setDisplayedAdvertisers(advertisersList.slice(0, lastFetchedIndex)); // Display first 20 advertisers
      setLoading(false); // Stop loading when data is fetched
    });
  }, [lastFetchedIndex]);

  const handleSearchChange = async (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = advertisers.filter(advertiser => {
      return (
        advertiser.firstName.toLowerCase().includes(term) ||
        advertiser.lastName.toLowerCase().includes(term) ||
        advertiser.businessUsername.toLowerCase().includes(term)
      );
    });

    setDisplayedAdvertisers(filtered.slice(0, lastFetchedIndex));
  };

  const loadMoreAdvertisers = () => {
    setLastFetchedIndex(prevIndex => prevIndex + 20); // Load 20 more advertisers
    setDisplayedAdvertisers(advertisers.slice(0, lastFetchedIndex + 20)); // Update displayed advertisers
  };

  const handleAdvertiserClick = (uid) => {
    navigate(`/business/${uid}`); // Navigate to BusinessProfile with the advertiser's uid
  };

  return (
    <div className="advertisers-page">
      <h2>Advertisers</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search advertisers by name or business username..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>

      {loading ? (
        <div className="loading-indicator">
          <Spinner animation="border" variant="primary" />
          <p>Loading advertisers...</p>
        </div>
      ) : (
        <div className="advertisers-list" onScroll={loadMoreAdvertisers}>
          {displayedAdvertisers.length === 0 ? (
            <p>No advertisers found.</p>
          ) : (
            displayedAdvertisers.map(advertiser => (
              <div
                key={advertiser.uid}
                className="advertiser-card"
                onClick={() => handleAdvertiserClick(advertiser.uid)} // Handle card click
              >
                <img
                  src={advertiser.profilePhotoURL || `https://via.placeholder.com/50`}
                  alt={`${advertiser.firstName} ${advertiser.lastName}`}
                  className="profile-pic"
                />
                <div className="advertiser-info">
                  <h3>{`${advertiser.firstName} ${advertiser.lastName}`}</h3>
                  <p>@{advertiser.businessUsername}</p> {/* Display business username */}
                  <p>{advertiser.businessType}</p>
                  <p>{advertiser.address}</p>
                  <p>{advertiser.phone}</p>
                  <p><a href={advertiser.website} target="_blank" rel="noopener noreferrer">Website</a></p>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default Advertisers;
