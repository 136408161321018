import React, { useEffect, useState } from 'react';
import { database } from '../firebaseConfig';
import { ref, onValue, get } from 'firebase/database';
import { useParams, useNavigate } from 'react-router-dom';
import './UserProfile.css';
import { Spinner, Dropdown } from 'react-bootstrap';
import Verify from '../assets/verifybadge.png';


const UserProfile = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      if (userData && userData.posts) {
        const db = database;
        const postIds = userData.posts;
        const fetchedPosts = [];
  
        for (const postId of postIds) {
          const postRef = ref(db, `posts/${postId}`);
          const postSnapshot = await get(postRef);
          if (postSnapshot.exists()) {
            fetchedPosts.push(postSnapshot.val());
          }
        }
  
        setPosts(fetchedPosts);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
  
    fetchPosts();
  }, [userData]);

  useEffect(() => {
    const userRef = ref(database, `/profiles/${userId}`);

    onValue(userRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        setUserData(data);
      } else {
        console.log('User not found!');
        navigate('/users');
      }

      setLoading(false);
    });
  }, [userId, navigate]);

  const handleHashtags = (text) => {
    return text.split(' ').map((word, index) => {
      if (word.startsWith('#')) {
        return (
          <span key={index} className="hashtag" onClick={() => navigate(`/hashtag/${word.slice(1)}`)}>
            {word}{' '}
          </span>
        );
      }
      return word + ' ';
    });
  };

  const renderMedia = (postmedia) => {
    if (!postmedia || postmedia.length === 0) return null;
    if (postmedia.length === 1) {
      return <img src={postmedia[0]} alt="Post media" className="post-media" />;
    }
    // Create a collage for multiple images
    return (
      <div className="media-collage">
        {postmedia.map((media, index) => (
          <img key={index} src={media} alt={`Media ${index}`} className={`collage-item item-${index + 1}`} />
        ))}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="loading-indicator">
        <Spinner animation="border" variant="primary" />
        <p>Loading user profile...</p>
      </div>
    );
  }

  if (!userData) {
    return <div>User not found</div>;
  }

  return (
    <div className="profile-page">
      <div className="profile-header-section">
        <div className="profile-cover-photo">
          <img src={userData.profileCover || 'https://via.placeholder.com/150'} alt="Cover Photo" className="cover-photo" />
        </div>

        <div className="profile-main-card">
        <div className="profile-photo-container">
        <img src={userData.profilePicture || 'https://via.placeholder.com/150'} alt="Profile" className="profile-photo" />
        </div>

          <div className="profile-info">
            <h2>{`${userData.firstname} ${userData.lastname}`}{userData.verified && (
            <img src={Verify} alt="Verified" className="verify-badge-small" />
        )}</h2>
            <h5>{userData.bio}</h5>
            <p className="email-info">{userData.email}</p>
            <p>{userData.college}</p>
            <p>{`${userData.collegeYear} - ${userData.stream}`}</p>

            <p>
            {userData.facebook ? (
                <a href={userData.facebook} target="_blank" rel="noopener noreferrer">
                Facebook Profile
                </a>
            ) : (
                'No facebook link'
            )}
            </p>

            <p>
            {userData.instagram ? (
                <a href={userData.instagram} target="_blank" rel="noopener noreferrer">
                Instagram Profile
                </a>
            ) : (
                'No instagram link'
            )}
            </p>

            <p>{userData.youtube || 'No youtube link'}</p>

            <p>{userData.website ? (
                <a href={userData.website} target="_blank" rel="noopener noreferrer">
                Website
                </a>
            ) : (
                'No website link'
            )}
            </p>

            <p>{userData.website ? (
                <a href={userData.linkedin} target="blank" rel="noopener noreferrer">
                linkedin Profile
                </a>
            ) : (
                'No linkedin link'
            )}
            </p>

            <div className="profile-stats">
              <div className="stat-item">
                <span className="stat-value">{userData.posts ? Object.keys(userData.posts).length : 0}</span>
                <span className="stat-label">Posts</span>
              </div>

              <div className="stat-item">
                <span className="stat-value">{userData.followers ? userData.followers.length : 0}</span>
                <span className="stat-label">Followers</span>
              </div>

              <div className="stat-item">
                <span className="stat-value">{userData.following ? userData.following.length : 0}</span>
                <span className="stat-label">Following</span>
              </div>
            </div>
          </div>

          <div className="profile-actions">
            <button className="action-button btn-primary">Follow</button>
            <button className="action-button btn-secondary">Message</button>
          </div>
        </div>
      </div>

      <div className="post-section">
        <h3>Posts</h3>
        {posts.length > 0 ? (
          posts.map((post, index) => (
            <div key={index} className="post">
              <div className="post-header">
                <img src={userData.profilePicture} alt="Poster" className="post-profile-pic" />
                <div>
                  <h4>{`${userData.firstname} ${userData.lastname}`}</h4>
                  <p>{new Date(parseInt(post.date)).toLocaleDateString()}</p>
                </div>

                <Dropdown className="post-more-options">
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    ⋮
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Edit Post</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Delete Post</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Report Post</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <p className="post-caption">{handleHashtags(post.caption)}</p>
              {renderMedia(post.postmedia)}

              <div className="post-actions">
                <span className="likes-count">{post.likes ? post.likes.length : 0} Likes</span>
                <button className="like-button">Like</button>
                <button className="comment-button">Comment</button>
                <button className="share-button">Share</button>
              </div>
            </div>
          ))
        ) : (
          <p>No posts available.</p>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
