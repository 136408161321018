// src/pages/Login.js
import React, { useState } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth,database } from '../firebaseConfig';
import { ref, get } from 'firebase/database';
import './Login.css';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message on each login attempt
  
    try {
      // Sign in with email and password
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log("Logged in", userCredential);
  
      // Get user profile data
      const profileRef = ref(database, `/profiles/${userCredential.user.uid}`);
      const profileSnapshot = await get(profileRef); // Use get for a single read
  
      if (profileSnapshot.exists()) {
        const profileData = profileSnapshot.val();
  
        if (profileData.role === 'admin') {
          // Successful login and role check
          navigate('/dashboard'); 
        } else {
          // User is not an admin
          setError('You are not an admin');
          await auth.signOut(); // Sign out the non-admin user
        }
      } else {
        // Handle case where profile doesn't exist
        setError('User profile not found.');
        await auth.signOut(); 
      }
  
    } catch (error) {
      // Handle login errors
      setError('Invalid email or password. Please try again.');
      console.error(error);
    }
  };
  

  return (
    <div className="login-container">
      <h2>Admin Login</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-btn">Login</button>
      </form>
    </div>
  );
};

export default Login;
