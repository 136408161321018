import React, { useEffect, useState, useCallback } from 'react';
import { database } from '../firebaseConfig'; // Import your firebaseConfig
import { ref, onValue } from 'firebase/database';
import './Users.css'; // Import CSS for styling
import { Spinner } from 'react-bootstrap'; // Import Bootstrap Spinner
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate


const Users = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]); // Users to display
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [loading, setLoading] = useState(true); // Loading state
  const [filter, setFilter] = useState('all'); // State to track the current filter
  const [lastFetchedIndex, setLastFetchedIndex] = useState(20); // To track the last fetched index
  const handleUserClick = (userId) => {
    navigate(`/user/${userId}`); // Navigate to UserProfile with the user's uid
  };

  useEffect(() => {
    // Fetch user data from Firebase Realtime Database
    const usersRef = ref(database, '/profiles');
    onValue(usersRef, (snapshot) => {
      const usersData = snapshot.val();
      const usersList = [];

      for (const uid in usersData) {
        if (usersData.hasOwnProperty(uid)) {
          usersList.push({ uid, ...usersData[uid] });
        }
      }

      setUsers(usersList);
      setDisplayedUsers(usersList.slice(0, lastFetchedIndex)); // Display first 20 users
      setLoading(false); // Stop loading when data is fetched
    });
  }, []);

  const handleSearchChange = async (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    // First, filter from the displayed users
    const filtered = displayedUsers.filter(user => {
      return (
        user.firstname.toLowerCase().includes(term) ||
        user.lastname.toLowerCase().includes(term) ||
        user.username.toLowerCase().includes(term)
      );
    });

    if (filtered.length > 0) {
      setDisplayedUsers(filtered);
    } else {
      // If no users found, search the database
      searchInDatabase(term);
    }
  };

  const searchInDatabase = async (term) => {
    const usersRef = ref(database, '/profiles');
    onValue(usersRef, (snapshot) => {
      const usersData = snapshot.val();
      const usersList = [];

      for (const uid in usersData) {
        if (usersData.hasOwnProperty(uid)) {
          usersList.push({ uid, ...usersData[uid] });
        }
      }

      const filtered = usersList.filter(user => {
        return (
          user.firstname.toLowerCase().includes(term) ||
          user.lastname.toLowerCase().includes(term) ||
          user.username.toLowerCase().includes(term)
        );
      });

      setDisplayedUsers(filtered);
    });
  };

  const handleFilterChange = (filterType) => {
    setFilter(filterType);
    let filtered;

    switch (filterType) {
      case 'recentlyJoined':
        filtered = [...users].sort((a, b) => b.joindate - a.joindate);
        break;
      case 'oldest':
        filtered = [...users].sort((a, b) => a.joindate - b.joindate);
        break;
      case 'city':
        filtered = users.filter(user => user.city === 'YourCity'); // Replace 'YourCity' with desired city
        break;
      case 'college':
        filtered = users.filter(user => user.college === 'YourCollege'); // Replace 'YourCollege' with desired college
        break;
      default:
        filtered = users; // Show all users
    }

    setDisplayedUsers(filtered.slice(0, lastFetchedIndex)); // Reset displayed users with filter
  };

  const loadMoreUsers = () => {
    setLastFetchedIndex(prevIndex => prevIndex + 20); // Load 20 more users
    setDisplayedUsers(users.slice(0, lastFetchedIndex + 20)); // Update displayed users
  };

  return (
    <div className="users-page">
      <h2>Users</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search users by name or username..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>
      <div className="filter-buttons">
        <button onClick={() => handleFilterChange('all')}>All Users</button>
        <button onClick={() => handleFilterChange('recentlyJoined')}>Recently Joined</button>
        <button onClick={() => handleFilterChange('oldest')}>Oldest</button>
        <button onClick={() => handleFilterChange('city')}>City Wise</button>
        <button onClick={() => handleFilterChange('college')}>College Wise</button>
      </div>

      {loading ? (
        <div className="loading-indicator">
          <Spinner animation="border" variant="primary" />
          <p>Loading users...</p>
        </div>
      ) : (
        <div className="users-list" onScroll={loadMoreUsers}>
          {displayedUsers.length === 0 ? (
            <p>No users found.</p>
          ) : (
            displayedUsers.map(user => (
              
              <div key={user.uid} className="user-card" onClick={() => handleUserClick(user.uid)} >
                <img src={user.profilePicture || `https://via.placeholder.com/50`} alt={`${user.firstname} ${user.lastname}`} className="profile-pic" />
                <div className="user-info">
                  <h3>{`${user.firstname} ${user.lastname}`}</h3>
                  <p>@{user.username}</p> {/* Display username */}
                  <p>{user.college}</p>
                  <p>{user.collegeYear}-{user.stream}</p>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default Users;
